import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { setAuthenticated, setUser } from '../reducers/actions';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Menu from '../componentes/menu';
import { SttCircularProgress, SttCacheManager, SttAlerta } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import Emails from './emails';
import Notificacoes from './notificacoes';
import alerta from '../signals/alerta';
import Administrativo from './administrativo';
import Erro401 from './erro/401';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Index = ({ isAuthenticated, setAuthenticated, setUser }) => {
    useSignals();
    const classes = useStyles();

    return (
        <div>
            {
                isAuthenticated
                    ?
                    <div className={classes.wrapper}>
                        <BrowserRouter>
                            <Menu />
                            <Switch>
                                <Route path="/nao-autorizado" exact component={Erro401} />
                                <Route path={['/', '/emails']} exact component={Emails} />
                                <Route path='/notificacoes' exact component={Notificacoes} />
                                <Route path='/administrativo' exact component={Administrativo} />
                            </Switch>
                            <SttAlerta {...alerta.value} />
                        </BrowserRouter>
                    </div>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            setAuthenticated(loaded);
                            const user = Cache.getUserInfo();
                            setUser(user);
                        }} />
                    </>
            }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        isAuthenticated: state.index.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticated(authenticated)),
        setUser: (user) => dispatch(setUser(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
