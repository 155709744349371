import * as yup from 'yup'
import { ENVIO, FILTROS } from './fieldNames';

const hoje = new Date();
hoje.setHours(0, 0, 0, 0);

export const validationSchema = (strings) => {
    return yup.object().shape({
        [FILTROS.FILTROS]: yup.object()
            .test('filtro-valido', strings.filtroObrigatorio, function () {
                if (
                    !this.parent.filtros.modulo.length &&
                    !this.parent.filtros.perfil.length &&
                    !this.parent.filtros.funcionario.length &&
                    !this.parent.filtros.cboFamilia.length &&
                    !this.parent.filtros.estado.length &&
                    !this.parent.filtros.municipio.length &&
                    !this.parent.filtros.cnes.length &&
                    !this.parent.filtros.emailsExternos.length
                ) return false;

                return true;
            }),
        [ENVIO.ENVIO]: yup.object().shape({
            [ENVIO.CATEGORIA]: yup.object().shape({
                descricao: yup.string(),
                exige_complemento: yup.boolean()
            }).nullable().required(strings.campoObrigatorio),
            [ENVIO.COMPLEMENTO_CATEGORIA]: yup.string().when(ENVIO.CATEGORIA, {
                is: (val) => val?.exige_complemento,
                then: yup.string().max(255).trim().required(strings.campoObrigatorio)
            }),
            [ENVIO.ASSUNTO]: yup.string().max(255).trim().required(strings.campoObrigatorio),
            [ENVIO.CONTEUDO]: yup.string()
                .trim()
                .test('html-valido', strings.erroConteudoHtml, (html) => {
                    html = (html || '').replace(/<[^>]*>?/gm, '');
                    if (html.length === 0 || html.length >= 10) {
                        return true;
                    }
                    return false;
                }).required(strings.campoObrigatorio)
        })
    });
};