import React, { lazy, Suspense, useContext, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import axios from 'axios';
import { getHeadersFormData } from '../../../request';
import {
    SttContainer,
    SttAlerta,
    SttGrid,
    SttButton,
    SttLoading,
    SttTranslateHook,
    SttHeading,
    SttCircularProgress,
    SttDivider,
    SttExpansionPanel
} from '@stt-componentes/core';
import { FILTROS, ENVIO } from './fieldNames';
import { validationSchema } from './validationSchema';
import juice from 'juice';
import HttpStatus from 'http-status-codes';
import { styles } from './ckeditor-styles';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    expansionPanel: {
        width: '100%'
    }
}));

const initialValues = {
    [FILTROS.FILTROS]: {
        [FILTROS.CBO_FAMILIA]: [],
        [FILTROS.UF]: [],
        [FILTROS.MUNICIPIO]: [],
        [FILTROS.MODULO]: [],
        [FILTROS.CNES]: [],
        [FILTROS.PERFIL]: [],
        [FILTROS.FUNCIONARIOS]: [],
        [FILTROS.EMAILS_EXTERNOS]: [],
        [FILTROS.ADIOCIONAR_EMAILS_EXTERNOS]: '',
    },
    [ENVIO.ENVIO]: {
        [ENVIO.ASSUNTO]: '',
        [ENVIO.CATEGORIA]: null,
        [ENVIO.COMPLEMENTO_CATEGORIA]: '',
        [ENVIO.CONTEUDO]: '',
        [ENVIO.CABECALHO]: null,
        [ENVIO.RODAPE]: null,
        [ENVIO.ANEXOS]: []
    }
}

const Filtros = lazy(() => import('./filtros'));
const Envio = lazy(() => import('./envio'));

const FormCadastroEmail = ({ callbackSucesso, callback }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
    const [tipoAlerta, setTipoAlerta] = useState('success');
    const [mensagemAlerta, setMensagemAlerta] = useState(strings.mensagemEnviada);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'filtros': true,
        'envio': false,
    });
    const secaoFiltros = useRef(null);
    const secaoEnvio = useRef(null);

    const MENSAGENS_API_BASE_URL = global.gConfig.url_base_mensagens;

    const enviarForm = (dados, setSubmitting, resetForm) => {
        setSubmitting(true);

        const dadosSalvar = { ...dados };

        dadosSalvar.envio.conteudo = transformarCssCKEDITOR(dados);

        const formData = new FormData();

        const anexos = dadosSalvar.envio.anexos;
        anexos.forEach((file, index) => {
            if (file?.name) {
                formData.append(`anexos-${index}`, file);
            }
        });

        for (var key in dadosSalvar) {
            formData.append(key, JSON.stringify(dadosSalvar[key]));
        }

        axios.post(`${MENSAGENS_API_BASE_URL}/mensagem`, formData, { headers: getHeadersFormData(), timeout: 30000 })
            .then((response) => {
                console.log(response.data)
                setTipoAlerta('success');
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(strings.mensagemEnviada);
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: callbackSucesso
                }]);
                setOnCloseAlerta(() => callbackSucesso);
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroDesconhecido;
                setMensagemAlerta(msg);

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResposta = response.data;
                        let arrMensagem = [];
                        dadosResposta.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        setTituloAlerta(dadosResposta.message);
                        setMensagemAlerta(msg);
                    } else {
                        setTituloAlerta(strings.erro);
                    }
                } else {
                    setTituloAlerta(strings.erro);
                }
                setTipoAlerta('error');
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: handleCloseAlerta
                }]);
                setOnCloseAlerta(() => handleCloseAlerta);
            })
            .finally(() => {
                setSubmitting(false);
                setMostrarAlerta(true);
            });
    }

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = estado;
        setSecoesAbertas(novoSecoesAbertas);
    }

    const verificarSecoesComErro = (validateForm) => {
        validateForm().then((retorno) => {
            let node = null;
            if (retorno['filtros']) {
                abrirSecao('filtros', true);
                node = secaoFiltros.current;
            } else if (retorno['envio']) {
                abrirSecao('envio', true);
                node = secaoEnvio.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 200);
            }
        });
    }

    const transformarCssCKEDITOR = (data) => {
        return juice.inlineContent(data.envio.conteudo, styles);
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={callback}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            <SttHeading variant="h1" color="primary" className={classes.heading}>{strings.envioEmail}</SttHeading>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validateOnChange={false}
                validationSchema={validationSchema(strings)}
                onSubmit={(dados, { setSubmitting }) => {
                    setSubmitting(false);
                    setTituloAlerta(strings.atencao);
                    setMensagemAlerta(strings.confirmarEmail);
                    setTipoAlerta('alert');
                    setOpcoesAlerta([
                        {
                            title: strings.sim,
                            onClick: () =>
                                enviarForm(dados, setSubmitting)
                        },
                        {
                            title: strings.nao,
                            onClick: handleCloseAlerta
                        }
                    ]);
                    setOnCloseAlerta(() => () => handleCloseAlerta);
                    setMostrarAlerta(true);
                }}
            >
                {
                    ({
                        isSubmitting,
                        resetForm,
                        validateForm,
                        handleSubmit
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <SttExpansionPanel
                                    classegriditem={classes.expansionPanel}
                                    opened={secoesAbertas['filtros']}
                                    title={strings.destinatarios}
                                    callback={estadoInterno => abrirSecao('filtros', estadoInterno)}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoFiltros}></div>
                                            <Filtros />
                                        </Suspense>
                                    }
                                />
                                <SttDivider />
                                <SttExpansionPanel
                                    classegriditem={classes.expansionPanel}
                                    opened={secoesAbertas['envio']}
                                    title={strings.envio}
                                    callback={estadoInterno => abrirSecao('envio', estadoInterno)}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoEnvio}></div>
                                            <Envio />
                                        </Suspense>
                                    }
                                />
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <div className={classes.buttonWrapper}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                onClick={() => verificarSecoesComErro(validateForm)}
                                                disabled={isSubmitting}>
                                                {strings.enviar}
                                            </SttButton>
                                            <SttButton variant="outlined" color="primary" onClick={resetForm}>
                                                {strings.limpar}
                                            </SttButton>
                                        </div>
                                    </SttGrid>
                                </SttGrid>
                                <SttLoading open={isSubmitting} text={strings.salvandoNotificacao} />
                            </form>
                        )
                    }
                }
            </Formik>
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </SttContainer>
    );
};
export default FormCadastroEmail;