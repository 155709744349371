import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import {
    SttHeading,
    SttText,
    SttTranslateHook,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttSeeButton,
} from '@stt-componentes/core';
import Paper from '@material-ui/core/Paper';
import FormPesquisa from './form-pesquisa';
import FormCadastro from './form-cadastro';
import DetalhesMensagem from './detalhes';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(1.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        DATA_HORA_ENVIO: 'data_hora_envio',
        ASSUNTO: 'titulo',
        NUM_DESTINATARIOS: 'destinatarios',
        TIPO: 'tipo',
        CATEGORIA: 'descricao_categoria',
        RESPONSAVEL: 'responsavel_envio',
    }
}

const MensagensEnviadas = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(true);

    const [mensagens, setMensagens] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const [mensagemDetalhes, setMensagemDetalhes] = useState(null);
    const [cadastrarEmail, setCadastrarEmail] = useState(false);

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const resetPageOrder = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setMensagens(dados.itens);
        setBuscar(false);
    }

    const callbackSucesso = () => {
        setCadastrarEmail(false);
        setBuscar(true);
    }

    const callbackCadastro = () => {
        setCadastrarEmail(false);
    }

    if (cadastrarEmail) {
        return (
            <FormCadastro callbackSucesso={callbackSucesso}
                callback={callbackCadastro}
            />
        )
    }

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.mensagensEnviadas}
            </SttHeading>
            <FormPesquisa
                callbackBusca={callbackBusca}
                callbackAndamento={setBuscaEmAndamento}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
                resetPageOrder={resetPageOrder}
                setCadastrarEmail={setCadastrarEmail}
            />
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="11%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ENVIO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ENVIO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ENVIO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_HORA_ENVIO)}
                                    >
                                        {strings.dataHoraEnvio}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="13%" sortDirection={orderBy === ORDENACAO.CAMPOS.CATEGORIA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CATEGORIA}
                                        direction={orderBy === ORDENACAO.CAMPOS.CATEGORIA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CATEGORIA)}
                                    >
                                        {strings.categoria}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="36%" sortDirection={orderBy === ORDENACAO.CAMPOS.ASSUNTO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.ASSUNTO}
                                        direction={orderBy === ORDENACAO.CAMPOS.ASSUNTO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ASSUNTO)}
                                    >
                                        {strings.assuntoMensagemEnviada}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="18%" sortDirection={orderBy === ORDENACAO.CAMPOS.RESPONSAVEL ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.RESPONSAVEL}
                                        direction={orderBy === ORDENACAO.CAMPOS.RESPONSAVEL ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.RESPONSAVEL)}
                                    >
                                        {strings.responsavel}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.NUM_DESTINATARIOS ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NUM_DESTINATARIOS}
                                        direction={orderBy === ORDENACAO.CAMPOS.NUM_DESTINATARIOS ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUM_DESTINATARIOS)}
                                    >
                                        {strings.numeroDestinatarios}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="6%" sortDirection={orderBy === ORDENACAO.CAMPOS.TIPO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.TIPO}
                                        direction={orderBy === ORDENACAO.CAMPOS.TIPO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.TIPO)}
                                    >
                                        {strings.tipoMensagemEnviada}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="6%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan={2}>
                                    {strings.titulo}
                                </SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {buscaEmAndamento ? (
                            <SttTableRow key={-1}>
                                <SttTableCell colSpan={7} align="center">
                                    <SttCircularProgress />
                                </SttTableCell>
                            </SttTableRow>
                        ) : mensagens.length === 0 ? (
                            <SttTableRow key={-1}>
                                <SttTableCell colSpan={7} align="center">
                                    {strings.nenhumRegistroEncontrado}
                                </SttTableCell>
                            </SttTableRow>
                        ) : (
                            mensagens.map((row, index) => (
                                <SttTableRow key={index}>
                                    <SttHidden smDown>
                                        <SttTableCell>{row.data_hora_formatada}</SttTableCell>
                                        <SttTableCell>{row.descricao_categoria}{row.outra_categoria ? ` - ${row.outra_categoria}` : ''}</SttTableCell>
                                        <SttTableCell>{row.titulo}</SttTableCell>
                                        <SttTableCell>{row.responsavel_envio || strings.responsavelSistema}</SttTableCell>
                                        <SttTableCell>{row.destinatarios}</SttTableCell>
                                        <SttTableCell>{row.tipo}</SttTableCell>
                                    </SttHidden>
                                    <SttHidden mdUp>
                                        <SttTableCell width="100%">
                                            <SttText size="small">
                                                <b>{strings.dataHoraEnvio}:</b> {row.data_hora_formatada}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.categoria}:</b> {row.descricao_categoria}{row.outra_categoria ? ` - ${row.outra_categoria}` : ''}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.assunto}:</b> {row.titulo}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.responsavel}:</b> {row.responsavel_envio || strings.responsavelSistema}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.numeroDestinatarios}:</b> {row.destinatarios}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.tipo}:</b> {row.tipo}
                                            </SttText>
                                        </SttTableCell>
                                    </SttHidden>
                                    <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                        <SttSeeButton
                                            id={`btn-ver-solicitacao-${index}`}
                                            onClick={() => {
                                                console.log(row)
                                                setMensagemDetalhes(row)
                                            }}
                                        />
                                    </SttTableCell>
                                </SttTableRow>
                            ))
                        )}
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                mensagens.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros}
                    rowsPerPage={count}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
            {
                mensagemDetalhes &&
                <DetalhesMensagem
                    idMensagem={mensagemDetalhes.id}
                    setFecharModalDetalhes={() => setMensagemDetalhes(null)}
                />
            }
        </>
    );
};

export default MensagensEnviadas;