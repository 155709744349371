export const CONSTANTES = {
    TYPE: {
        EMAIL: 'EMAIL'
    },
    ATIVIDADE: {
        TELEMEDICINA: 'TM',
        TELESSAUDE: 'TS',
        AMBOS: 'A'
    }
};

export const PERMISSOES = {
    MENSAGENS: 'MENSAGENS_MENSAGENS',
};

export const CABECALHO = 'C';
export const RODAPE = 'R';

export const TIPOTEMPLATE = [
    { descricao: 'Cabeçalho', id: CABECALHO },
    { descricao: 'Rodapé', id: RODAPE }
]