import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import {
    SttHeading,
    SttText,
    SttTranslateHook,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttMenu,
    SttMenuItem,
    SttListItemIcon,
    SttListItemText,
    SttAlerta,
    SttLoading,
} from '@stt-componentes/core';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import Paper from '@material-ui/core/Paper';
import FormCadastro from './form-cadastro';
import FormPesquisa from './form-pesquisa';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { IconButton } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { getHeaders } from '../../request';
import moment from 'moment';
import { removeIdKeys } from '../../common/Functions';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        ASSUNTO: 'envio.assunto',
        CATEGORIA: 'envio.categoria.descricao',
        ATIVA: 'ativa',
        CONFIRMACAO_LEITURA: 'envio.leituraObrigatoria',
        FAVORITA: 'envio.favorita',
        DATA_HORA_CADASTRO: 'dataHoraCadastro'
    }
}

const MensagensEnviadas = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(ORDENACAO.CAMPOS.DESCRICAO);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [buscar, setBuscar] = useState(true);
    const [notificacoes, setNotificacoes] = useState([]);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    const [exibirLoading, setExibirLoading] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
    const [tipoAlerta, setTipoAlerta] = useState('success');
    const [mensagemAlerta, setMensagemAlerta] = useState(strings.mensagemEnviada);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const [cadastrarNotificacao, setCadastrarNotificacao] = useState(false);
    const [notificacaoAlteracao, setNotificacaoAlteracao] = useState(null);

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const callback = () => {
        setCadastrarNotificacao(false);
        setNotificacaoAlteracao(null);
    }

    const callbackSucesso = () => {
        setCadastrarNotificacao(false);
        setNotificacaoAlteracao(null);
        setBuscar(true);
    }

    const resetPageOrder = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setNotificacoes(dados.itens);
        setBuscar(false);
    }

    const excluirMensagem = (id) => {
        setExibirLoading(true);
        axios.delete(`${global.gConfig.url_base_mensagens}/notificacao`, { headers: getHeaders(), data: { id } })
            .then(() => {
                setTituloAlerta(strings.sucesso);
                setTipoAlerta('success');
                setMensagemAlerta(strings.sucessoExclusao);
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setBuscar(true);
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setBuscar(true);
                });
            })
            .catch(err => {
                console.log(err);
                const { response } = err;

                let mensagem = strings.erro;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => setMostrarAlerta(false)
                }]);
                setOnCloseAlerta(() => () => setMostrarAlerta(false));
            })
            .finally(() => {
                setMostrarAlerta(true);
                setExibirLoading(false);
            });
    }

    const inativarMensagem = (id) => {
        setExibirLoading(true);
        axios.post(`${global.gConfig.url_base_mensagens}/notificacao/inativacao`, { id }, { headers: getHeaders() })
            .then(() => {
                setTituloAlerta(strings.sucesso);
                setTipoAlerta('success');
                setMensagemAlerta(strings.sucessoInativacao);
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setBuscar(true);
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setBuscar(true);
                });
            })
            .catch(err => {
                console.log(err);
                const { response } = err;

                let mensagem = strings.erro;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => setMostrarAlerta(false)
                }]);
                setOnCloseAlerta(() => () => setMostrarAlerta(false));
            })
            .finally(() => {
                setMostrarAlerta(true);
                setExibirLoading(false);
            });
    }

    const ativarMensagem = (id) => {
        setExibirLoading(true);
        axios.post(`${global.gConfig.url_base_mensagens}/notificacao/ativacao`, { id }, { headers: getHeaders() })
            .then(() => {
                setTituloAlerta(strings.sucesso);
                setTipoAlerta('success');
                setMensagemAlerta(strings.sucessoAtivacao);
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setBuscar(true);
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setBuscar(true);
                });
            })
            .catch(err => {
                console.log(err);
                const { response } = err;

                let mensagem = strings.erro;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => setMostrarAlerta(false)
                }]);
                setOnCloseAlerta(() => () => setMostrarAlerta(false));
            })
            .finally(() => {
                setMostrarAlerta(true);
                setExibirLoading(false);
            });
    }

    return (
        <>
            {
                cadastrarNotificacao
                    ?
                    <FormCadastro callbackSucesso={callbackSucesso}
                        notificacaoAlteracao={notificacaoAlteracao}
                        callback={callback} />
                    :
                    <>
                        <SttHeading variant="h1" color="primary" className={classes.heading}>
                            {strings.notificacoes}
                        </SttHeading>
                        <FormPesquisa
                            callbackBusca={callbackBusca}
                            callbackAndamento={setBuscaEmAndamento}
                            page={page}
                            count={count}
                            orderBy={orderBy}
                            order={order}
                            buscar={buscar}
                            resetPageOrder={resetPageOrder}
                            setCadastrarNotificacao={setCadastrarNotificacao}
                        />
                        <TableContainer className={classes.tableWrapper} component={Paper}>
                            <SttTable>
                                <SttTableHead>
                                    <SttTableRow>
                                        <SttHidden smDown>
                                            <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_HORA_CADASTRO ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.DATA_HORA_CADASTRO}
                                                    direction={orderBy === ORDENACAO.CAMPOS.DATA_HORA_CADASTRO ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_HORA_CADASTRO)}
                                                >
                                                    {strings.dataHoraCadastro}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="37%" sortDirection={orderBy === ORDENACAO.CAMPOS.ASSUNTO ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.ASSUNTO}
                                                    direction={orderBy === ORDENACAO.CAMPOS.ASSUNTO ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.ASSUNTO)}
                                                >
                                                    {strings.assunto}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="21%" sortDirection={orderBy === ORDENACAO.CAMPOS.CATEGORIA ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.CATEGORIA}
                                                    direction={orderBy === ORDENACAO.CAMPOS.CATEGORIA ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.CATEGORIA)}
                                                >
                                                    {strings.categoria}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.CONFIRMACAO_LEITURA ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.CONFIRMACAO_LEITURA}
                                                    direction={orderBy === ORDENACAO.CAMPOS.CONFIRMACAO_LEITURA ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.CONFIRMACAO_LEITURA)}
                                                >
                                                    {strings.confirmacaoLeitura}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="5%" sortDirection={orderBy === ORDENACAO.CAMPOS.ATIVA ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.ATIVA}
                                                    direction={orderBy === ORDENACAO.CAMPOS.ATIVA ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.ATIVA)}
                                                >
                                                    {strings.ativa}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="7%" sortDirection={orderBy === ORDENACAO.CAMPOS.FAVORITA ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.FAVORITA}
                                                    direction={orderBy === ORDENACAO.CAMPOS.FAVORITA ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.FAVORITA)}
                                                >
                                                    {strings.favorita}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="5%" align="center">
                                                {strings.opcoes}
                                            </SttTableCell>
                                        </SttHidden>
                                        <SttHidden mdUp>
                                            <SttTableCell width="100%" colSpan={1}>
                                                {strings.notificacoes}
                                            </SttTableCell>
                                        </SttHidden>
                                    </SttTableRow>
                                </SttTableHead>
                                <SttTableBody>
                                    {buscaEmAndamento ? (
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={7} align="center">
                                                <SttCircularProgress />
                                            </SttTableCell>
                                        </SttTableRow>
                                    ) : notificacoes.length === 0 ? (
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={7} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                    ) : (
                                        notificacoes.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{moment(row.dataHoraCadastro).format('DD/MM/YYYY HH:mm')}</SttTableCell>
                                                    <SttTableCell>{row.envio.assunto}</SttTableCell>
                                                    <SttTableCell>{row.envio.categoria.descricao}</SttTableCell>
                                                    <SttTableCell >{row.envio.leituraObrigatoria ? strings.sim : strings.nao}</SttTableCell>
                                                    <SttTableCell>{row.ativa ? strings.sim : strings.nao}</SttTableCell>
                                                    <SttTableCell>{row.envio.favorita ? strings.sim : strings.nao}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.dataHoraCadastro}:</b> {moment(row.dataHoraCadastro).format('DD/MM/YYYY HH:mm')}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.assunto}:</b> {row.envio.assunto}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.categoria}:</b> {row.envio.categoria.descricao}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.confirmacaoLeitura}:</b> {row.envio.leituraObrigatoria ? strings.sim : strings.nao}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.ativa}:</b> {row.ativa ? strings.sim : strings.nao}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.favorita}:</b> {row.envio.favorita ? strings.sim : strings.nao}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <PopupState variant="popover">
                                                        {(popupState) => (
                                                            <>
                                                                <IconButton aria-haspopup="true" {...bindTrigger(popupState)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                                <SttMenu {...bindMenu(popupState)}>
                                                                    <SttMenuItem
                                                                        onClick={() => {
                                                                            popupState.close();
                                                                            row.envio.categoria.id = row.envio.categoria.identificadorSistemaOrigem;
                                                                            
                                                                            row = removeIdKeys(row);
                                                                            row.funcionariosLeitura = [];
                                                                            setNotificacaoAlteracao(row);
                                                                            setCadastrarNotificacao(true);
                                                                        }}
                                                                    >
                                                                        <SttListItemIcon>
                                                                            <FileCopyIcon />
                                                                        </SttListItemIcon>
                                                                        <SttListItemText primary={strings.duplicar} />
                                                                    </SttMenuItem>

                                                                    {
                                                                        row.ativa
                                                                            ?
                                                                            <SttMenuItem
                                                                                onClick={() => {
                                                                                    setTituloAlerta(strings.atencao);
                                                                                    setMensagemAlerta(strings.confirmarInativacao);
                                                                                    setTipoAlerta('alert');
                                                                                    setOpcoesAlerta([
                                                                                        {
                                                                                            title: strings.sim,
                                                                                            onClick: () => {
                                                                                                popupState.close();
                                                                                                inativarMensagem(row._id);
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            title: strings.nao,
                                                                                            onClick: handleCloseAlerta
                                                                                        }
                                                                                    ]);
                                                                                    setOnCloseAlerta(() => handleCloseAlerta);
                                                                                    setMostrarAlerta(true);
                                                                                }}
                                                                            >
                                                                                <SttListItemIcon>
                                                                                    <BlockIcon />
                                                                                </SttListItemIcon>
                                                                                <SttListItemText primary={strings.inativar} />
                                                                            </SttMenuItem>
                                                                            :
                                                                            <SttMenuItem
                                                                                onClick={() => {
                                                                                    setTituloAlerta(strings.atencao);
                                                                                    setMensagemAlerta(strings.confirmarAtivacao);
                                                                                    setTipoAlerta('alert');
                                                                                    setOpcoesAlerta([
                                                                                        {
                                                                                            title: strings.sim,
                                                                                            onClick: () => {
                                                                                                popupState.close();
                                                                                                ativarMensagem(row._id);
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            title: strings.nao,
                                                                                            onClick: handleCloseAlerta
                                                                                        }
                                                                                    ]);
                                                                                    setOnCloseAlerta(() => handleCloseAlerta);
                                                                                    setMostrarAlerta(true);
                                                                                }}
                                                                            >
                                                                                <SttListItemIcon>
                                                                                    <CheckIcon />
                                                                                </SttListItemIcon>
                                                                                <SttListItemText primary={strings.ativar} />
                                                                            </SttMenuItem>
                                                                    }
                                                                    {
                                                                        (!row.funcionariosLeitura || row.funcionariosLeitura.length === 0) &&
                                                                        <SttMenuItem
                                                                            onClick={() => {
                                                                                popupState.close();
                                                                                row.envio.categoria.id = row.envio.categoria.identificadorSistemaOrigem;
                                                                                const _id = row._id;
                                                                                row = removeIdKeys(row);
                                                                                row._id = _id;
                                                                                console.log(row)
                                                                                setNotificacaoAlteracao(row)
                                                                                setCadastrarNotificacao(true)
                                                                            }}
                                                                        >
                                                                            <SttListItemIcon>
                                                                                <EditIcon />
                                                                            </SttListItemIcon>
                                                                            <SttListItemText primary={strings.alterar} />
                                                                        </SttMenuItem>
                                                                    }
                                                                    {
                                                                        (!row.funcionariosLeitura || row.funcionariosLeitura.length === 0) &&
                                                                        <SttMenuItem
                                                                            onClick={() => {
                                                                                setTituloAlerta(strings.atencao);
                                                                                setMensagemAlerta(strings.confirmarExclusao);
                                                                                setTipoAlerta('alert');
                                                                                setOpcoesAlerta([
                                                                                    {
                                                                                        title: strings.sim,
                                                                                        onClick: () => {
                                                                                            popupState.close();
                                                                                            excluirMensagem(row._id);
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        title: strings.nao,
                                                                                        onClick: handleCloseAlerta
                                                                                    }
                                                                                ]);
                                                                                setOnCloseAlerta(() => handleCloseAlerta);
                                                                                setMostrarAlerta(true);
                                                                            }}
                                                                        >
                                                                            <SttListItemIcon>
                                                                                <DeleteIcon />
                                                                            </SttListItemIcon>
                                                                            <SttListItemText primary={strings.excluir} />
                                                                        </SttMenuItem>
                                                                    }

                                                                </SttMenu>
                                                            </>
                                                        )}
                                                    </PopupState>
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                    )}
                                </SttTableBody>
                            </SttTable>
                        </TableContainer>
                        {
                            notificacoes.length > 0 &&
                            <SttTablePagination
                                rowsPerPageOptions={[10, 20, 40]}
                                component="div"
                                count={totalRegistros}
                                rowsPerPage={count}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={strings.linhasPorPagina}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                            />
                        }
                    </>
            }
            <SttLoading open={exibirLoading} />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </>
    );
};
export default MensagensEnviadas;
