export const FILTROS = {
    FILTROS: 'filtros',
    CBO_FAMILIA: "cboFamilia",
    UF: "estado",
    MUNICIPIO: "municipio",
    CNES: 'cnes',
    MODULO: 'modulo',
    PERFIL: 'perfil',
    FUNCIONARIOS: 'funcionario',
    EMAILS_EXTERNOS: 'emailsExternos',
    ADIOCIONAR_EMAILS_EXTERNOS: 'adicionarEmailsExternos',
};

export const ENVIO = {
    ENVIO: 'envio',
    CATEGORIA: 'categoria',
    COMPLEMENTO_CATEGORIA: 'complementoCategoria',
    ASSUNTO: 'assunto',
    CONTEUDO: 'conteudo',
    ANEXOS: 'anexos',
    CABECALHO: 'cabecalho',
    RODAPE: 'rodape',
};

export const ATIVA = 'ativa';